import React, { useEffect, useRef } from 'react';
import { Player, ControlBar, ForwardControl, ReplayControl, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';

function App() {
  const playerRef = useRef(null);

  // Post a message to React Native when fullscreen is toggled
  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ event: 'fullscreen', status: true }));
    } else {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ event: 'fullscreen', status: false }));
    }
  };

  useEffect(() => {
    // Add fullscreen event listener
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Extract video URL from query parameters
  const params = new URLSearchParams(window.location.search);
  const videoUrl = params.get('videoUrl') || '';

  return (
    videoUrl !== '' &&
    <div style={{ width: '100%', height: 'calc(100vw / 1.778)' }}>
      <Player ref={playerRef} src={videoUrl} fluid>
        <BigPlayButton position="center" />
        <ControlBar>
          <ReplayControl seconds={10} />
          <ForwardControl seconds={10} />
        </ControlBar>
      </Player>
    </div>
  );
}

export default App;
